


import Component from 'vue-class-component';
import { Vue} from 'vue-property-decorator';
import IconMap from '@/_modules/icons/components/icon-map.vue';
import HelpCrunchService from '@/_services/help-crunch.service';
@Component({
  name: 'cabinet-event-map',
  components: {IconMap},
  computed: {
  }
})
export default class CabinetEventMap extends Vue {

  private openSupportChat(event: Event): void {
    if (event && event.target) {
      const helpCrunchInstance = HelpCrunchService._helpCrunch;
      if (!helpCrunchInstance) { // TODO: also check for instance.isInitialized and test it
        window.setTimeout(() => { this.openSupportChat(event); }, 1000);
        return;
      }
      helpCrunchInstance('openChat');
    }
  }
}
